<template>
	<div>
		<el-row><canvas ref="myChart" style="width: 95%;height: 200%;"></canvas></el-row>
		<el-row><canvas ref="myChart2" style="width: 80%;height: 40%;margin-top:100px;"></canvas></el-row>
	</div>
</template>

<script>
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
	name: 'ChartDemo',
	data() {
		return {};
	},
	mounted() {
		const labels = ['12am', '', '', '3', '', '', '6', '', '', '9', '', '', '12pm', '', '', '', '', '', '6', '', '', '9', '', '', '12am'];
		const data = {
			labels: labels,
			datasets: [
				{
					label: '血糖',
					backgroundColor: 'black',
					borderColor: 'black',
					borderWidth: 1,
					data: [150, 290, 10, 212, 120, 80, 95, 150, 290, 10, 212, 120, 80, 95, 150, 290, 10, 212, 120, 80, 95, 150, 290, 10, 212],
					fill: false,
					tension: 0.5,
					yAxisID: 'y'
				},
				{
					label: 'Low',
					backgroundColor: '#FF4932',
					borderColor: '#FF4932',
					borderWidth: 1,
					data: [70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70, 70],
					fill: { above: '#FD8B80', below: 'transparent', target: 0 },
					yAxisID: 'y'
				},
				{
					label: 'High',
					backgroundColor: '#FFA846',
					borderColor: '#FFA846',
					borderWidth: 1,
					data: [180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180, 180],
					fill: { above: 'transparent', below:'rgba(0, 0, 0, 0.1)', target: 0 },
					yAxisID: 'y'
				}
			]
		};

		const chartAreaBorder = {
			id: 'chartAreaBorder',
			beforeDraw(chart, args, options) {
				const {
					ctx,
					chartArea: { left, top, width, height }
				} = chart;
				ctx.save();
				ctx.strokeStyle = options.borderColor;
				ctx.lineWidth = options.borderWidth;
				ctx.setLineDash(options.borderDash || []);
				ctx.lineDashOffset = options.borderDashOffset;
				ctx.strokeRect(left, top, width, height);
				ctx.restore();
			}
		};

		const config = {
			type: 'line',
			interaction: {
				mode: 'index',
				intersect: false
			},
			stacked: false,
			data: data,
			options: {
				responsive: false,
				pointRadius: 0,
				showLine: true,
				plugins: {
					chartAreaBorder: {
						borderColor: '#6C6C69',
						borderWidth: 2,
						borderDash: [0, 0],
						borderDashOffset: 20
					}
				},
				scales: {
					x: {
						id: 'x1',
						grid: {
							color: '#EFEFEF',
							tickColor: 'black',
							borderColor: '#BCBCB6',
							borderWidth: 3,
							circular: true,
							z: 0
						},
						autoSkipPadding: 10
					},
					y: {
						type: 'linear',
						ticks: {
							stepSize: 100
						},
						suggestedMax: 400,
						suggestedMin: 0,
						position: 'left'
					},
					y1: {
						type: 'linear',
						display: true,
						position: 'right',
						max: 400,
						min: 0,
						stacked: false,
						grid: {
							tickColor: 'transparent',
							drawOnChartArea: false
						},
						ticks: {
							stepSize: 10,
							autoSkip: false,
							color: ['#FFA846', '#FF4932'],
							font: {
								weight: '800'
							},
							callback: function(label) {
								if (label === 70 || label === 180) {
									return label;
								} else {
									return '';
								}
							}
						}
					}
				}
			},
			plugins: [chartAreaBorder]
		};

		this.$nextTick(function() {
			new Chart(this.$refs.myChart, config);
		});

		this.setChart2();
	},
	methods: {
		setChart2() {
			const labels = [
				'12am','','','','','','','','','','','',
				'3','','','','','','','','','','','',
				'6','','','','','','','','','','','',
				'9','','','','','','','','','','','',
				'12pm','','','','','','','','','','','',
				'3','','','','','','','','','','','',
				'6','','','','','','','','','','','',
				'9','','','','','','','','','','','',
				'12am'
			];

			const data = {
				labels: labels,
				datasets: [
					{
						label: 'Fully Rounded',
						data: [150, 290, 50, 192, 120, 80, 95],
						borderColor: '#C7C7C7',
						backgroundColor: '#C7C7C7',
						borderWidth: 1,
						borderRadius: Number.MAX_VALUE,
						borderSkipped: false
					}
				]
			};

			const config = {
				type: 'bar',
				data: data,
				options: {
					responsive: true,
					plugins: {
						legend: {
							position: 'top'
						},
						title: {
							display: true,
							text: 'Chart.js Bar Chart'
						}
					}
				}
			};

			this.$nextTick(function() {
				new Chart(this.$refs.myChart2, config);
			});
		}
	}
};
</script>

<style scoped></style>
